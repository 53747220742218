<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import SubNav from "@/components/SubNav";
import SvgIcon from '@/components/SvgIcon';
import { computed } from 'vue';

/**
 * VUE COMPONENT
 * --------------------------------------------------------------------------------
 */
export default {
  setup() {
    const currentYear =  process.env.VUE_APP_YEAR || 2020;
    const route       = useRoute();
    const store       = useStore();
    const country     = store.state.countries.find(d => d.url == route.params.country);
    const items       = computed( () => store.getters.getCountryIndices(country));
    const textShort   = computed( () => store.getters.getShortText(country.id));

    const getIndex = index => {
      if(!items.value) return null;
      return store.getters.getValueFromIndexWithCountryAndYear(index, country.id, currentYear)
    }

    /**
     * TEMPLATE ELEMENTS
     * --------------------------------------------------------------------------------
     **/
    return {
      country,
      getIcon : store.getters.getIcon,
      getIndex,
      format : store.getters.formatNumber,
      textShort,
      currentYear
    };
  },

  /**
   * COMPONENTS
   * --------------------------------------------------------------------------------
   **/
  components: {
    SubNav,
    SvgIcon
  },
};
</script>



<template>
  <section class="im_country">
    <div class="container">
      <div class="row">
        <div class="col-sm-4">
          <h1 class="mb-4">
            <strong>{{ country.name }}</strong>
          </h1>
          <div class="ip_separator bottom titles"></div>
          <p class="im_label mb-0">
            <svg-icon
              :icon="getIcon('personas')"
              :hasFill="true"
              class="od_svg_icon icon_title"
            ></svg-icon>
            GASTO PER CAPITA EN VIH
          </p>
          <h4 v-if="getIndex('per_capita')"><span class="im_amount sm">${{format(getIndex('per_capita'))}}</span> USD</h4>
          <p v-else class="od_danger mb-3">Sin información</p>

          <div class="ip_separator bottom titles"></div>
          <p class="im_label mb-0">
            <svg-icon
              :icon="getIcon('personas')"
              :hasFill="true"
              class="od_svg_icon icon_title"
            ></svg-icon>
            GASTO EN VIH 
          </p>
          <h4 v-if="getIndex('gasto_en_vih')">
            <span class="im_amount sm">
              ${{format(getIndex('gasto_en_vih'))}}
            </span>
            {{getIndex('gasto_en_vih_moneda')}}
          </h4>
          <p v-else class="od_danger mb-3">Sin información</p>
          <div class="ip_separator bottom titles"></div>
          <p class="im_label mb-0">
            <svg-icon
              :icon="getIcon('personas')"
              :hasFill="true"
              class="od_svg_icon icon_title"
            ></svg-icon>
            Nivel de ingresos
          </p>
          <p v-if="country.level">{{country.level}}</p>
          <p v-else class="od_danger mb-3">Sin información</p>
          <div class="ip_separator bottom titles"></div>
          <p class="im_label mb-0">
            <svg-icon
              :icon="getIcon('personas')"
              :hasFill="true"
              class="od_svg_icon icon_title"
            ></svg-icon>
            RADIO DE MORTALIDAD
          </p>
          <p v-if="getIndex('incidencia:_radio_de_mortalidad')"> {{getIndex('incidencia:_radio_de_mortalidad')}}</p>
          <p v-else class="od_danger mb-3">Sin información</p>
          <div class="ip_separator bottom titles"></div>
          <p class="im_label mb-0">
            <svg-icon
              :icon="getIcon('personas')"
              :hasFill="true"
              class="od_svg_icon icon_title"
            ></svg-icon
            >Personas en tratamiento con antirretrovirales
          </p>
          <p v-if="getIndex('personas_que_viven_con_vih_que_reciben_tarv')">{{format(getIndex('personas_que_viven_con_vih_que_reciben_tarv'))}}
            <span v-if="getIndex('porcentaje_de_personas_que_viven_con_vih_que_reciben_tarv')">
              ({{ format( getIndex('porcentaje_de_personas_que_viven_con_vih_que_reciben_tarv') )}}%)
            </span>
          </p>
          <p v-else class="od_danger mb-3">Sin información</p>
        </div>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-3">
              <div class="ip_separator bottom titles"></div>
              <p class="im_label mb-0">
                <svg-icon
                  :icon="getIcon('personas')"
                  :hasFill="true"
                  class="od_svg_icon icon_title"
                ></svg-icon>
                Población
              </p>
              <p>{{format(getIndex('población')/1000000)}} millones</p>
            </div>
            <div class="col-sm-4">
              <div class="ip_separator bottom titles"></div>
              <p class="im_label mb-0">
                <svg-icon
                  :icon="getIcon('personas')"
                  :hasFill="true"
                  class="od_svg_icon icon_title"
                ></svg-icon>
                Personas viviendo con VIH
              </p>
              <p v-if="getIndex('personas_que_viven_con_vih')">{{format(getIndex('personas_que_viven_con_vih'))}}</p>
              <p v-else class="od_danger mb-3">Sin información</p>
            </div>
            <div class="col-sm-5">
              <div class="ip_separator bottom titles"></div>
              <p class="im_label mb-0">
                <svg-icon
                  :icon="getIcon('gasto')"
                  :hasFill="true"
                  class="od_svg_icon icon_title"
                ></svg-icon>
                INCIDENCIA por cada 100 mil personas
              </p>
              <p v-if="getIndex('tasa_de_incidencia_por_cada_100_mil_personas')">{{format(getIndex('tasa_de_incidencia_por_cada_100_mil_personas')*100)}}</p>
              <p v-else class="od_danger mb-3">Sin información</p>
            </div>
          </div>

          <div class="row mt-4 pt-4">
            <div class="col-sm-11 offset-sm-1">
              <p class="od_card_legend">
                {{textShort}}
              </p>
            </div>
          </div>
          
        </div>
      </div>
      <div class="row">
          <div class="col-sm-12">
            <p class="text-right mb-0 od_source">Información correspondiente a {{currentYear}}. Fuentes: Onusida, Banco Mundial, Informes GAM. </p>
          </div>
        </div>
    </div>
  </section>
  
  <div class="container">
    <div class="row">
      <div class="col-12">
      <!--
        <form :action="`/${country.url}/busqueda`" class="pb-4">
          <p class="mb-4 pb-4">
            <input
              name="query"
              class="od_search"
              placeholder="Buscador general por país"
            />
          </p>
        </form>-->
        <sub-nav :country="country" />
      </div>
    </div>

    <div>
      <router-view :country="country"></router-view>
    </div>
    
    <!-- <div class="mb-4 pb-4">
      <div class="ip_separator bottom"></div>
      <p class="mb-4 pb-4 text-right">
        Descargar los datos abiertos de <strong>{{ country.name }}</strong
        >:
        <a
          class="od_dl_btn mr-3 ml-3"
          :href="`data:text/csv;charset=utf-8, ${encodeURIComponent(
            indicesCSV
          )}`"
          :download="`indicadores-${getSlug(country)}.csv`"
          ><svg-icon icon="descarga" :hasFill="true"></svg-icon>Indicadores</a
        >
        <a
          class="od_dl_btn"
          :href="`data:text/csv;charset=utf-8, ${encodeURIComponent(
            contractsCSV
          )}`"
          :download="`contratos-${getSlug(country)}.csv`"
          ><svg-icon icon="descarga" :hasFill="true"></svg-icon>Contratos</a
        >
      </p>
    </div> -->
  </div>
</template>
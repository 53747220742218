<script>
export default{
  props : ['country']
}
</script>



<template>
  <div class="row mb-4 pb-4">
    <div class="col mb-4">
      <router-link :to="{ name: 'Situation'}" class="od_subnav_btn">
        Situación en {{country.name}}
      </router-link>
    </div>
    <div class="col mb-4">
      <router-link :to="{ name: 'CountryStatus'}" class="od_subnav_btn">
        Semáforo
      </router-link>
    </div>
    <div class="col mb-4">
      <router-link :to="{ name: 'EfficiencyObjective1'}" class="od_subnav_btn">
        Eje 1. Eficiencia
      </router-link>
    </div>
    <div class="col mb-4">
      <router-link :to="{ name: 'RelevanceObjective1'}" class="od_subnav_btn">
        Eje 2. Pertinencia de la inversión
      </router-link>
    </div>
    <div class="col mb-4">
      <router-link :to="{ name: 'ServicesObjective3'}" class="od_subnav_btn">
        Eje 3. Servicios
      </router-link>
    </div>
    <div class="col mb-4" v-if="country.contracts">
      <router-link :to="{ name: 'Contracts'}" class="od_subnav_btn">
        Contratos
      </router-link>
    </div>
    <div class="col mb-4">
      <router-link :to="{ name: 'Publications'}" class="od_subnav_btn">
        Publicaciones
      </router-link>
    </div>
    
  </div>
</template>